<template>
  <front-layout :title="$t('Forms')">
    <div class="flex flex-col md:flex-row">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Forms')" />
        <a class="btn-red md:hidden" href="#" @click.prevent="showNewFormModal()">
          {{ $t('New Form') }}
        </a>
      </div>
      <div class="mb-6 flex w-full flex-col md:flex-row md:items-center md:justify-end">
        <front-search-filter
          v-model="form.form_search"
          class="flex w-full flex-col mb-4 md:mb-0 md:mx-4 sm:flex-row lg:max-w-sm"
          :filter-show="false"
        />
        <div class="flex w-full justify-center md:mb-0 md:order-first md:pr-4 md:w-auto md:justify-end">
          <div class="relative flex items-center">
            <div
              class="flex h-10 w-64 items-center rounded-md bg-gray-200 px-1 shadow-inner transition-colors duration-300 ease-in-out"
            >
              <div
                class="absolute inset-y-0.5 w-1/2 rounded-md shadow transform transition-transform duration-300 ease-in-out"
                :class="form.status ? 'right-0.5 bg-ts-red-500' : 'left-0.5 bg-ts-red-500'"
              ></div>
              <button
                class="z-10 flex-1 py-2 text-center text-sm font-medium"
                :class="!form.status ? 'text-white' : 'text-gray-700'"
                type="button"
                @click="setStatus('in-progress')"
              >
                {{ $t('In Progress') }}
              </button>
              <button
                class="z-10 flex-1 py-2 text-center text-sm font-medium"
                :class="form.status ? 'text-white' : 'text-gray-700'"
                type="button"
                @click="setStatus('completed')"
              >
                {{ $t('Completed') }}
              </button>
            </div>
          </div>
        </div>
        <a class="btn-red hidden md:block" href="#" @click.prevent="showNewFormModal()">
          {{ $t('New Form') }}
        </a>
      </div>
    </div>
    <section :class="[filledForms.data.length ? 'space-y-2 overflow-x-auto' : 'space-y-2']">
      <div
        v-if="!filledForms.data.length"
        class="flex flex-col items-center justify-center py-12 px-4 bg-white rounded-lg border shadow-sm"
      >
        <svg
          class="h-12 w-12 text-gray-400 mb-4"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="text-lg font-medium text-gray-700">{{ $t('No forms available') }}</span>
        <p class="mt-1 text-sm text-gray-500">
          {{ form.status ? $t('No completed forms found.') : $t('No in-progress forms found.') }}
        </p>
      </div>
      <template v-else>
        <div
          v-for="filledForm in filledForms.data"
          :key="filledForm.id"
          class="flex cursor-pointer flex-col rounded-lg border-x border-b bg-white px-6 pb-4 pt-6 lg:flex-row lg:items-start"
        >
          <div class="flex-1 space-y-4 lg:pr-8">
            <h3 class="font-semibold">{{ filledForm.form_template.title }}</h3>
            <div class="flex flex-col justify-start md:flex-row">
              <div class="flex gap-2 pr-4 md:w-1/3 md:flex-col md:gap-0">
                <span class="text-xs text-ts-gray-400">{{ $t('Started At') }}</span>
                <front-date class="text-xs" format="MMMM Do YYYY" :timestamp="filledForm.created_at" />
              </div>
              <div class="flex gap-2 pr-4 md:w-1/3 md:flex-col md:gap-0">
                <span class="text-xs text-ts-gray-400">{{ $t('Last Updated') }}</span>
                <front-date class="text-xs" format="MMMM Do YYYY" :timestamp="filledForm.updated_at" />
              </div>
              <div v-if="filledForm.completed_at" class="flex gap-2 pr-4 md:w-1/3 md:flex-col md:gap-0">
                <span class="text-xs text-ts-gray-400">{{ $t('Completed At') }}</span>
                <front-date class="text-xs" format="MMMM Do YYYY" :timestamp="filledForm.completed_at" />
              </div>
            </div>
          </div>
          <div
            class="flex justify-center whitespace-nowrap text-sm font-medium leading-5 lg:w-1/4 lg:justify-end xl:w-1/5"
          >
            <front-outline-button-link
              v-if="
                filledForm.signature_requests.length && filledForm.signature_requests[0].signature_type !== 'assignee'
              "
              class="my-2 inline-block"
              :href="
                route('front.filledForms.complete', {
                  formFilled: filledForm.id,
                  signatureRequestId: filledForm.signature_requests[0].id,
                })
              "
            >
              {{ $t('Review & Sign') }}
            </front-outline-button-link>
            <front-outline-button-link
              v-else-if="filledForm.completed_at"
              class="my-2 inline-block"
              :href="route('front.filledForms.view', filledForm.id)"
            >
              {{ $t('View Report') }}
            </front-outline-button-link>
            <front-outline-button-link
              v-else-if="filledForm.review_status === 'in-progress'"
              class="my-2 inline-block"
              :href="route('front.filledForms.complete', filledForm.id)"
            >
              {{ $t('In Review') }}
            </front-outline-button-link>
            <front-outline-button-link
              v-else-if="filledForm.review_status === 'completed'"
              class="my-2 inline-block"
              :href="route('front.filledForms.complete', filledForm.id)"
            >
              {{ $t('Review Done') }}
            </front-outline-button-link>
            <front-outline-button-link
              v-else
              class="my-2 inline-block"
              :href="route('front.filledForms.edit', filledForm.id)"
            >
              {{ $t('Continue') }}
            </front-outline-button-link>
          </div>
        </div>
      </template>
    </section>
    <NewFormModal :show="isShowNewFormModal" @close="hideNewFormModal" />
  </front-layout>
</template>

<script>
import NewFormModal from '../Forms/NewFormModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: { FrontDate, FrontOutlineButtonLink, NewFormModal, Breadcrumb, FrontLayout, FrontSearchFilter },
  props: {
    filters: Object,
    filledForms: Object,
    filledFormsInReview: Object,
  },
  data() {
    console.log('Initial filters:', this.filters)
    const hasStatus = this.filters.status !== undefined && this.filters.status !== null
    return {
      form: {
        template_search: this.filters.template_search || '',
        form_search: this.filters.form_search || '',
        status: hasStatus ? this.filters.status === 'completed' : false,
      },
      isShowNewFormModal: false,
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        const statusValue = this.form.status ? 'completed' : 'in-progress'
        console.log('Toggle changed - setting status to:', statusValue)

        let query = _pickBy({
          template_search: this.form.template_search,
          form_search: this.form.form_search,
          status: statusValue,
        })

        console.log('Query being sent:', query)

        this.$inertia.get(this.route('front.filledForms.index'), query, {
          preserveScroll: true,
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    showNewFormModal() {
      this.isShowNewFormModal = true
    },
    hideNewFormModal() {
      this.isShowNewFormModal = false
    },
    setStatus(status) {
      this.form.status = status === 'completed'
    },
  },
}
</script>

<style scoped>
.toggle-button {
  position: relative;
  overflow: hidden;
}

.toggle-slider {
  transition: transform 0.3s ease;
}

.toggle-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(221, 22, 61, 0.3);
}
</style>
